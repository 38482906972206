/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
    font-family: Poppins;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --header-height: 72px;
    --footer-height: 229px;

    @media screen and (max-width: 777px) {
        --footer-height: 341px;
    }
}

.headerHeight {
    height: var(--header-height)
}

.content {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
    width: 100%;
}

.textArea {
    min-height: 50px;
    border-radius: 15px;
    border: 2px solid #b5b5b5;
    padding: 12px 15px;
    outline: none;
    transition: 0.2s;
    resize: none;

    font-size: 18px;
    font-family: Inter, sans-serif;
    font-weight: 400;
    color: #000;
}

.textArea:focus {
    border: 2px solid #28619d;
}

.textArea::placeholder {
    color: #535353;
}

/* scroll */
/* ===== Scrollbar CSS ===== */
/* Firefox */
.textArea {
    scrollbar-width: auto;
    font-family: Poppins;
    scrollbar-color: #28619d #ffffff00;
}

.textArea::placeholder {
    color: #747474;
}

/* Chrome, Edge, and Safari */
.textArea::-webkit-scrollbar {
    width: 15px;
}

.textArea::-webkit-scrollbar-track {
    background: #ffffff00;
}

.textArea::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
    background-clip: content-box;
    border: 5px solid transparent;
}

.button {
    background-color: #fa9310;
    color: #fff;
    border: none;
    border-radius: 30px;
    padding: 0px 20px;
    padding-top: 1px;
    font-size: 13px;
    font-family: Poppins;
    font-weight: 600;
    outline: none;
    transition: 0.2s;
    cursor: pointer;
    height: 45px;
    user-select: none;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button:active {
    background-color: #e48100;
}

.buttonNormal {
    height: 40px !important;
    font-size: 0.9rem !important;
}

.inputNormal {
    height: 45px !important;
    font-size: 16px !important;
    padding-bottom: 13px !important;
}

.input {
    border-radius: 15px;
    border: 2px solid #b5b5b5;
    padding: 12px 15px;
    font-size: 18px;
    font-family: Poppins;

    font-weight: 400;
    color: #000;
    outline: none;
    transition: 0.2s;
    width: 100%;
    height: 51px;
}

.input:focus {
    border: 2px solid #28619d;
}

.buttonOutline {
    color: #fff;
    border-radius: 30px;
    padding: 0px 20px;
    padding-top: 1px;
    
    font-size: 13px;
    font-family: Poppins;
    font-weight: 600;

    background: #1189af00;
    border: 2px solid #fa9310;

    outline: none;
    transition: 0.2s;
    cursor: pointer;
    height: 45px;
    user-select: none;
    transition: 0.2s;
}

.buttonOutline:active {
    background-color: #ebebeb33;
}

.panelClass>mat-dialog-container>.mdc-dialog__container>.mat-mdc-dialog-surface {
    background-color: transparent !important;
    border-radius: 17px !important;
}

/* ng material spinner white */
.white-spinner circle{
    color: #fff !important;
    stroke: #fff !important;
}

.poppins {
    font-family: Poppins, sans-serif;
}

.swal2-confirm {
    border-radius: 100px !important;
    padding: 15px 25px;
}

.swal2-cancel {
    border-radius: 100px !important;
    padding: 15px 25px;
}